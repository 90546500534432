.Success {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 30px;
  text-align: center;

  @media screen and (max-width: 600px) {
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__title {
    font-weight: normal;
    margin-bottom: 40px;
    line-height: 1.4;
    margin-top: 20px;
  }
}
