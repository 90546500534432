.RoundedButton {
  height: 40px;
  background-color: #33C02A;
  border-radius: 40px;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #F7821A;
  }

  &:active {
    background-color: #C3C8CB;
  }
}
