.Popup {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.2);
  margin: auto;
  position: relative;

  &__header {
    border-bottom: 1px solid #D7D9DA;
    height: 90px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #000;
    font-size: 32px;
    font-weight: bold;
  }

  &__text {
    font-size: 26px;
    color: #000;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 50px;
  }

  &__icon {
    text-align: center;
    margin-bottom: 30px;
    font-size: 0;
  }

  &__body {
    padding: 40px 50px;
  }

  &__afterText {
    position: absolute;
    top: 100%;
    color: #494949;
    margin-top: 30px;
    font-size: 16px;
    text-align: center;
    left: 0;
    right: 0;

    a {
      color: #367DF0;
      text-decoration: none;
    }
  }

  &__footer {
    background-color: #F7F7F7;
    padding: 25px 30px;
    border-top: 1px solid #D7D9DA;
    text-align: center;

    p {
      color: #91979F;
      font-size: 12px;
      margin: 0;

      a {
        color: #494949;
        text-decoration: none;

        &:hover {
          color: #367DF0;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    &__title {
      font-size: 24px;
    }

    &__header {
      height: 74px;

      img {
        width: 44px;
      }
    }

    &__body {
      padding: 30px 20px;
    }

    &__text {
      font-size: 22px;
    }
  }
}
