.Radio {
  &:not(:last-child) {
    margin-bottom: 15px;
  }


  input {
    display: none;
  }

  label {
    color: #000;
    font-size: 15px;
    padding-left: 26px;
    position: relative;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url("images/off.svg");
      position: absolute;
      left: 0px;
      top: 50%;
      margin-top: -8px;
    }
  }

  input:checked + label {
    &:before {
      background-image: url("images/on.svg");
    }
  }
}
