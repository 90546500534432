.CookiesAlert {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.2);
  width: 100%;
  max-width: 360px;

  &__text {
    color: #494949;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 24px;

    a {
      color: #3663F0;
      text-decoration: none;
    }
  }
}
