.Socials {
  margin-bottom: 30px;

  &__title {
    color: #494949;
    font-size: 16px;
  }

  &__buttons {
    display: flex;
    margin-top: 30px;
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    height: 60px;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 10px;
    }

    img {
      margin-right: 10px;
    }

    &:hover {
      border-color: #3663F0;
    }

    @media screen and (max-width: 600px) {
      height: 50px;
      font-size: 14px;

      img {
        max-width: 24px;
      }
    }
  }
}
