.Form {

  &__row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__body {
    margin-bottom: 40px;
  }

  &__text {
    color: #494949;
    font-size: 16px;
    margin-bottom: 30px;

    a {
      color: #367DF0;
      text-decoration: none;

      &:hover {
        color: #33C02A;
      }
    }
  }

  &__input {
    outline: none;
    background-color: #F7F7F7;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    color: #000;
    font-size: 16px;
    padding: 0px 15px;
    width: 100%;

    &:focus {
      background-color: #fff;
      border-color: #3663F0;
    }

    @media screen and (max-width: 600px) {
      height: 44px;
      font-size: 14px;
    }
  }

  &__errors {
    padding-top: 13px;
    text-align: center;
  }

  &__error {
    color: #FE2F2F;
    margin-top: 12px;
    font-size: 14px;
  }
}
