.Section {
  margin-bottom: 40px;

  &__title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: capitalize;

    span {
      color: #FE2F2F;
    }
  }

  &__card {
    border: 1px solid #D7D9DA;
    border-radius: 3px;
    background-color: #fff;
    overflow: hidden;
  }

  &__row {
    padding: 25px 30px;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #D7D9DA;
    }
  }

  @media screen and (max-width: 600px) {
    &__title {
      font-size: 22px;
    }

    &__row {
      padding: 20px;
      overflow: auto;
    }
  }
}
