.Blocked {
  background-color: #FE2F2F;
  padding: 30px;

  &__top {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__bottom {
    color: #FFB2B2;
    font-size: 16px;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
