.Error {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #FE2F2F;
  padding: 20px;
  color: #fff;
  font-size: 20px;
  text-align: center;
}
