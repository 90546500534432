.SocialRow {
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }

  &__info {

  }

  &__top {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
  }

  &__value {
    color: #494949;
    font-size: 16px;
    margin-top: 6px;
  }
}
