.Header {
  height: 90px;
  background-color: #fff;
  border-bottom: 1px solid #D7D9DA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;

  &__right {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    height: 60px;
    justify-content: center;

    &__right {
      display: none;
    }

    img {
      width: 160px;
    }

    .Header__hamburger {
      display: block;
    }
  }
}


.Header_active {
  composes: Header;


  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .Header__right {
    display: block !important;
    background-color: #fff;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 3;
    padding: 40px;

    nav {
      flex-direction: column;
      justify-content: center;

      a {
        margin-right: 0px;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.Header__hamburger {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -5px;
  display: none;
  width: 18px;

}

.Header__hamburger div {
  height: 2px;
  background-color: #91979F;
  border-radius: 2px;
}

.Header__hamburger div:not(:last-child) {
  margin-bottom: 3px;
}
