html {
    background-color: #F7F7F7;
    font-family: Arial, sans-serif;
}

html, body {
    height: 100%;
}

html, body, #root {
    min-height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
