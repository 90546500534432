.Table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    text-align: left;
    font-weight: bold;
  }

  th, td {
    padding: 10px;
    line-height: 1.4;
    font-size: 12px;
  }

  tr:not(:last-child) td, tr:not(:last-child) th {
    border-bottom: 1px solid #D7D9DA;
  }

  td {
    color: #494949;
  }
}
