.Navigation {
  display: flex;
  align-items: center;

  &__link {
    color: #3663F0;
    font-size: 18px;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:hover {
      color: #33C02A;
    }

    &--active {
      composes: Navigation__link;
      pointer-events: none;
      position: relative;
      color: #000;

      &:after {
        content: "";
        display: block;
        height: 5px;
        background-color: #3663F0;
        position: absolute;
        left: 0;
        right: 0;
        top: 45px;
      }
    }
  }
}
