.Home {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 30px;

  &__inner {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}


.Remainings {
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__left {
    font-size: 16px;
  }

  &__right {
    color: #91979F;
    font-size: 16px;
  }

  &__progress {
    height: 10px;
    border-radius: 10px;
    background-color: #D7D9DA;
    overflow: hidden;
  }

  &__used {
    height: 100%;
    border-radius: 10px;
    background-color: #33C02A;
  }

  &__text {
    color: #494949;
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
    max-width: 420px;

    span {
      color: #FE2F2F;
    }
  }
}

.Default {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
    margin-right: 30px;
  }

  &__text {
    font-size: 14px;
    color: #494949;
    margin-top: 8px;
    line-height: 1.4;
  }

  &__title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }

  &__button {
    border: 1px solid #D7D9DA;
    border-radius: 3px;
    height: 40px;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    color: #3663F0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 38px;
    padding: 0px 40px;
    cursor: pointer;
  }
}
