.Button__default {
  width: 100%;
  border: none;
  height: 60px;
  background-color: #3663F0;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #367DF0;
  }

  &:active {
    background-color: #C3C8CB;
  }

  @media screen and (max-width: 600px) {
    height: 50px;
    font-size: 14px;
  }
}

.Button__secondary {
  composes: Button__default;
  background: none;
  border: 2px solid #3663F0;
  color: #3663F0;
  margin-top: 10px;

  &:hover {
    border: none;
    color: #fff;
  }
}


.Button__medium {
  composes: Button__default;
  height: 50px;
  font-size: 15px;
}


.Button__small {
  composes: Button__default;
  height: 40px;
  font-size: 14px;
}

.Loader, .Loader::before, .Loader::after {
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.Loader {
  width: 60px;
  height: 60px;
  margin: auto;
  background: no-repeat;
  background-image: radial-gradient(closest-side, #fff 90%, rgba(0, 0, 0, 0)), radial-gradient(closest-side, #fff 90%, rgba(0, 0, 0, 0));
  background-size: 10px 10px;
  background-position: 0%, 50%;
  position: relative;
  animation-name: slide;
}

.Loader::before, .Loader::after {
  content: '';
  width: 10px;
  height: 10px;
  background: radial-gradient(closest-side, #fff 90%, rgba(0, 0, 0, 0));
  position: absolute;
  top: 50%;
  margin-top: -5px;
  animation-name: reveal;
}

.Loader::before {
  left: 1%;
  animation-delay: 40ms;
}
.Loader::after {
  right: 1%;
  animation-direction: reverse;
}

@keyframes slide {
  from {
    background-position: 0%, 50%;
  }
  to {
    background-position: 50%, 100%;
  }
}
@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}
